<template lang="pug">
    .agendamento

        //- Animação de carregamento
        .py-56(v-if='waiting.page')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.page')

        .flex.items-center.justify-center.flex-wrap(v-else)
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                div(v-if='! agendamentoCriado')
                    //- Etapa 1
                    .px-4.py-8(class='md:py-36' v-if='agendamento.tipoConsulta === null')
                        .absolute.top-0.left-0.p-2
                            a.text-3xl(@click='$router.go(-1)')
                                i.jam.jam-chevron-left
                        .flex-col.items-center
                            img.mx-auto.w-40.mb-4(src='./../../assets/img/odonto/logo.png')
                            .flex.justify-center.mb-4
                                .rounded-full.w-40.h-40(style="background-color: #33B5FF")
                                    LottieAnimation.static(path="lottie/dente.json" :loop='false')
                            h1.text-center.text-2xl.text-gray-700.font-semibold Qual serviço você procura?
                            .mx-auto.w-full.mb-4(class='md:w-1/3')
                                .p-2.w-full
                                    b-button.w-full(type='is-primary' rounded size='is-medium' @click='selectConsulta(true)')
                                        span.flex.items-center
                                            img.h-5.inline.mr-2(src="./../../assets/img/odonto/consulta.png")
                                            span.text-white Consultas
                                .p-2.w-full
                                    b-button.w-full.bg-red-600( rounded size='is-medium' @click='selectConsulta(false, "EO")')
                                        span.flex.items-center
                                            img.h-6.inline.mr-2( src="./../../assets/img/odonto/dental.png")
                                            span.text-white Exames
                                .p-2.w-full
                                    b-button.w-full.bg-green-600( rounded size='is-medium' @click='selectConsulta(false, "PO")')
                                        span.flex.items-center
                                            img.h-6.inline.mr-2(src="./../../assets/img/odonto/proc.png")
                                            span.text-white Procedimentos

                    //- Etapa 2 para consultas
                    .px-4.py-8(class='md:py-36' v-else-if='pesquisarPor === null && agendamento.tipoConsulta === true')
                        .absolute.top-0.left-0.p-2
                            a.text-3xl(@click='agendamento.tipoConsulta = null')
                                i.jam.jam-chevron-left
                        .flex.flex-wrap.justify-center.animate__animated.animate__fadeIn
                            .w-full.text-center.mb-4
                                img.mx-auto.mb-4(style='width:20em' src='./../../assets/img/odonto/odonto-agendar.png')
                                h1.text-2xl.text-gray-700.font-semibold Como prefere agendar sua consulta?
                            div.mx-auto.w-full.mb-4(class='md:w-1/3')
                                .p-2.w-full
                                    b-button.w-full(type='is-primary' rounded size='is-medium' icon-pack='jam' icon-left='jam-user' @click="selectFluxo('medico')") Nome do dentista
                                .p-2.w-full
                                    b-button.w-full.bg-red-600(style='color:white !important' rounded size='is-medium' icon-pack='jam' icon-left='jam-medical' @click="selectFluxo('especialidade')") Especialidade
                                .p-2.w-full
                                    b-button.w-full.bg-green-600(style='color:white !important' rounded size='is-medium' icon-pack='jam' icon-left='jam-building' @click="selectFluxo('data')") Local de atendimento
                                .p-2.w-full
                                    b-button.w-full(type='is-info' rounded size='is-medium' icon-pack='jam' icon-left='jam-activity' @click="selectFluxo('sintoma')") Área de Atuação

                    //- Etapa 3 para consultas
                    div(v-else-if='agendamento.tipoConsulta === true')
                        .flex.flex-wrap.justify-center
                            .w-full(v-if="pesquisarPor === 'medico'")
                                SelecionarEspecialista(v-if='! agendamento.especialidade')
                                SelecionarEstabelecimento(v-else-if='! agendamento.estabelecimento')
                                SelecionarHorario(v-else)

                            .w-full(v-if="pesquisarPor === 'especialidade'")
                                SelecionarEspecialidade(v-if='! agendamento.especialidade')
                                SelecionarEspecialista(v-else-if='! agendamento.especialista')
                                SelecionarEstabelecimento(v-else)

                            .w-full(v-if="pesquisarPor === 'data'")
                                SelecionarEspecialidade(v-if='! agendamento.especialidade')
                                SelecionarEstabelecimento(v-else-if='! agendamento.estabelecimento')
                                SelecionarHorario(v-else)
                            .w-full(v-if="pesquisarPor === 'sintoma'")
                                SelecionarSintomas(v-if='! agendamento.sintomas' :isOdonto='true')
                                SelecionarEspecialista(v-else-if='! agendamento.especialista')
                                SelecionarEstabelecimento(v-else-if='! agendamento.estabelecimento')
                                SelecionarHorario(v-else)

                    //- Etapa 2 para exames e procedimentos
                    SelecionarExames(v-else-if='! agendamento.exames')
                    //- SelecionarEstabelecimentoExames(v-else-if="agendamento.ie_tipo_agenda === 'EO'")
                    SelecionarEstabelecimentoProcedimentos(v-else)

                div(v-else)
                    SelecionarPaciente

</template>

<script>
import SelecionarSintomas from "./Etapas/SelecionarSintomas";
import SelecionarEspecialista from "./Etapas/Especialista";
import SelecionarEstabelecimento from "./Etapas/EstabelecimentoConsulta";
import SelecionarEstabelecimentoExames from "./Etapas/EstabelecimentoExames.vue";
import SelecionarEstabelecimentoProcedimentos from "./Etapas/EstabelecimentoData.vue";
import SelecionarEspecialidade from "./Etapas/Especialidade";
import SelecionarHorario from "./Etapas/HorarioConsulta";
import SelecionarPaciente from "./Etapas/Paciente";
import SelecionarExames from "./Etapas/SelecionarExames";
import { Agendamento } from "../../middleware";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import moment from "moment";

export default {
  components: {
    SelecionarEspecialista,
    SelecionarEstabelecimento,
    SelecionarEstabelecimentoExames,
    SelecionarEstabelecimentoProcedimentos,
    SelecionarEspecialidade,
    SelecionarHorario,
    SelecionarSintomas,
    SelecionarPaciente,
    SelecionarExames,
    LottieAnimation,
  },
  data() {
    return {
      agendamento: {
        ie_tipo_agenda: "C",
        is_odonto: true,
        ie_telemedicina: false,
        tipoConsulta: null,
        sintomas: null,
        ie_hora_marcada: null,
        especialista: null,
        especialidade: null,
        agenda: null,
        estabelecimento: null,
        horario: null,
        paciente: null,
        exames: null,
      },
      waiting: { page: false },
      pesquisarPor: null,
      agendamentoCriado: false,
      link_url: null,
    };
  },
  created() {
    //Penúltima etapa do agendamento
    if (this.$route.params.pesquisar_por && this.$route.params.slug) {
      this.waiting.page = true;
      let pesquisar_por = this.$route.params.pesquisar_por;

      let params = {
        ie_tipo_procedimento: "OD",
        ie_telemedicina: this.$route.params.ds_telemedicina === "por-video",
      };

      // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
      if (!params.ie_telemedicina) params.cd_cidade = this.$store.state.cdCidade;

      switch (pesquisar_por) {
        case "data":
          {
            // eslint-disable-next-line no-unused-vars
            const searching_doctors = this.$route.params.slug && this.$route.params.slug.split("_").length === 4;

            if (searching_doctors) {
              // eslint-disable-next-line no-unused-vars
              const [_, cd_especialidade, cd_estabelecimento, dt_agenda] = this.$route.params.slug.split("_");

              this.waiting.page = true;

              this.pesquisarPor = pesquisar_por;
              this.agendamento.ie_telemedicina = this.$route.params.ds_telemedicina === "por-video";

              // - SET STABLISHMENT FROM ID IN URL
              // TODO: endpoint to just get the establishment by id
              // get establishment agenda params
              const params = {
                cd_estabelecimento: cd_estabelecimento,
                cd_especialidade: Number(cd_especialidade),
                ie_telemedicina: this.agendamento.ie_telemedicina,
                ie_tipo_agenda: this.agendamento.ie_tipo_agenda,
                dt_agenda: dt_agenda,
              };
              this.waiting.estabelecimentos = true;

              Agendamento.getEstabelecimentos(params).then((response) => {
                this.waiting.estabelecimentos = false;
                if (response.status === 200) {
                  const estabelecimentos = Object.values(response.data).length ? Object.values(response.data)[0] : [];

                  const estabelecimento_by_slug = estabelecimentos[0];
                  if (estabelecimento_by_slug) this.agendamento.estabelecimento = estabelecimento_by_slug;
                }
              });

              // - SET ESPECIALIDADE FROM ID IN URL
              const params_especialidades = {
                ie_telemedicina: this.agendamento.ie_telemedicina,
              };
              // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
              if (!params_especialidades.ie_telemedicina) params_especialidades.cd_cidade = this.$store.state.cdCidade;
              params_especialidades.ie_tipo_procedimento = "OD";
              Agendamento.getProcedimentos(params_especialidades).then((response) => {
                this.waiting.especialidades = false;
                if (response.status === 200) {
                  const especialidades = response.data;
                  console.log({
                    especialidades: especialidades,
                    cd_especialidade: cd_especialidade,
                  });
                  const especialidade_by_slug = especialidades.find((e) => e.id === Number(cd_especialidade));
                  if (especialidade_by_slug) this.agendamento.especialidade = especialidade_by_slug;
                }
              });

              this.agendamento._data = dt_agenda ? moment(dt_agenda).toDate() : null;

              this.pesquisarPor = pesquisar_por;
              this.agendamento.tipoConsulta = true;

              this.waiting.page = false;
            } else {
              Agendamento.getProcedimentos(params).then((response) => {
                this.waiting.page = true;
                if (response.status === 200) {
                  const especialidade = response.data.find((e) => e.slug === this.$route.params.slug);
                  if (especialidade) {
                    this.pesquisarPor = pesquisar_por;
                    this.agendamento.tipoConsulta = true;
                    this.agendamento.especialidade = {
                      id: especialidade.id,
                      nm_especialidade: especialidade.nm_especialidade,
                    };
                    this.waiting.page = false;
                  }
                }
              });
            }
          }
          break;
        case "especialidade":
          {
            Agendamento.getProcedimentos(params).then((response) => {
              this.waiting.page = true;
              if (response.status === 200) {
                const especialidade = response.data.find((e) => e.slug === this.$route.params.slug);
                if (especialidade) {
                  this.pesquisarPor = pesquisar_por;
                  this.agendamento.especialidade = {
                    id: especialidade.id,
                    nm_especialidade: especialidade.nm_especialidade,
                  };
                  this.waiting.page = false;
                } else {
                  const params = {
                    ie_telemedicina: this.agendamento.ie_telemedicina,
                  };
                  // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
                  if (!params.ie_telemedicina) params.cd_cidade = this.$store.state.cdCidade;

                  // slug from url
                  // eslint-disable-next-line no-unused-vars
                  const [_, slug_id_medico, slug_id_especialidade, slug_date] = this.$route.params.slug.split("_");

                  params.cd_especialidade = slug_id_especialidade;

                  this.agendamento._data = slug_date ? moment(slug_date).toDate() : null;

                  // buscar medico por id
                  Agendamento.getEspecialistas2(params)
                    .then((response) => {
                      if (response.status === 200) {
                        console.log({
                          data: response.data,
                        });
                        const especialista = response.data.find(
                          (profissional) => profissional.id === parseInt(slug_id_medico)
                        );
                        const especialidade = especialista.cd_especialidade.find(
                          (especialidade) => especialidade.id == parseInt(slug_id_especialidade)
                        );
                        this.agendamento.especialidade = especialidade;
                        this.agendamento.especialista = especialista;
                        this.pesquisarPor = pesquisar_por;
                        this.agendamento.tipoConsulta = true;
                      }
                    })
                    .finally(() => {
                      this.waiting.page = false;
                    });

                  //this.$router.push({ name: "agendarConsulta" });
                }
              }
            });
          }
          break;
        case "medico": {
          // eslint-disable-next-line no-unused-vars
          const [_, slug_id_medico, slug_id_especialidade, slug_date] = this.$route.params.slug.split("_");

          this.pesquisarPor = pesquisar_por;
          this.agendamento.ie_telemedicina = this.$route.params.ds_telemedicina === "por-video";

          const FIND_DOCTOR_PARAM = {
            cd_especialista: Number(slug_id_medico),
          };

          Agendamento.getEspecialistas2(FIND_DOCTOR_PARAM).then((response) => {
            if (response.status === 200) {
              console.log({
                data: response.data,
                param: slug_id_medico,
                slug_id_especialidade,
              });
              const especialista = response.data[0];
              const especialidade = especialista.cd_especialidade.find(
                (especialidade) => especialidade.id == parseInt(slug_id_especialidade)
              );

              this.agendamento.especialidade = especialidade;
              this.agendamento.especialista = especialista;
              this.pesquisarPor = pesquisar_por;
              this.agendamento.tipoConsulta = true;

              this.agendamento._data = slug_date ? moment(slug_date).toDate() : null;

              this.waiting.page = false;
            }
          });
        }
      }
    } else if (this.$route.params.pesquisar_por) {
      let ds_telemedicina = this.$route.params.ds_telemedicina === "por-video";
      this.selectConsulta(ds_telemedicina);
      this.selectFluxo(this.$route.params.pesquisar_por);
    }
  },
  watch: {
    pesquisarPor: function(val) {
      if (val === null) {
        let clear = ["especialista", "especialidade", "agenda", "estabelecimento", "horario"];
        clear.forEach((field) => (this.agendamento[field] = null));
      } else {
        if (this.agendamento.ie_consulta) {
          this.agendamento.estabelecimento = { id: 13 }; // consulta por vídeo
          this.agendamento.ie_hora_marcada = true;
        }
      }
    },
  },
  methods: {
    selectConsulta(flag, tipoAgenda = "C") {
      this.$gtag.event("selecionou_consulta_por_video", { selecionou_consulta_por_video: flag });
      this.agendamento.tipoConsulta = flag;

      this.agendamento.ie_tipo_agenda = tipoAgenda;
    },
    selectFluxo(fluxo) {
      this.$gtag.event("consulta_fluxo_selecionado", { fluxo_selecionado: `por ${fluxo}` });
      this.pesquisarPor = fluxo;
    },
  },
};
</script>
