<template lang="pug">
    .w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='back()')
                i.jam.jam-chevron-left

        .pt-4.pb-3.text-center.w-full
            .px-8.mb-2.justify-between
                span.text-gray-600.text-xs(v-if='!$parent.agendamento.is_odonto') {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
            h1.font-bold.text-medclub-blue-300.text-sm Escolha o {{ !$parent.agendamento.is_odonto ? 'médico' : 'dentista'}}

        .absolute.top-0.right-0.p-3
            a.text-2xl(@click='dialog.maisFiltros=true')
                i.jam.jam-filter
        .p-1.relative
            b-input(id='search-especialista' placeholder='Pesquisar por nome' icon='magnify' v-model='nm_especialista' @input='isTypingLocal = true')
            .absolute.top-0.right-0.p-2
                .cursor-pointer.py-1.px-2.bg-opacity-75.rounded-full.flex.items-center.bg-medclub-blue-300.text-white.t-0.r-0(v-if='nm_especialista' @click='limpar()')
                    b-icon(pack='jam' icon='jam-trash')
                    span.text-xs Limpar

        .py-56(v-if='waiting.especialistas')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.especialistas')
        .w-full(v-else)

            .flex.flex-wrap.pb-4
                .p-1.w-full.text-center(v-if='! list.length')
                    p.text-sm.my-6 Nenhum especialista encontrado.
                .p-1.w-full(v-else v-for='especialista, idx in list' :key='idx')
                    .bg-white.p-4.shadow-md.rounded.border.cursor-pointer(@click='selectEspecialista(especialista, $event)')
                        .flex.flex-wrap.items-center
                            .p-1
                                .w-10.h-10.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(class='md:w-16 md:h-16'
                                    :style="`background-image: url('${ especialista.aq_foto }')`")

                            .p-1.pl-4(class='w-5/6 md:w-11/12')
                                p.text-sm.font-bold.text-medclub-blue-400(class='md:text-lg') {{ especialista.ie_sexo == 'M' ? 'Dr' : 'Dra' }} {{ especialista.nm_especialista }}
                                p.text-sm.text-gray-600 {{ especialista.nm_conselho }} {{ especialista.uf_conselho }} - {{ especialista.nr_conselho }}
                                .flex.items-center(v-if="especialista.nr_avaliacao")
                                    star-rating(
                                        :rating="especialista.nr_avaliacao"
                                        :read-only="true"
                                        :show-rating="false"
                                        :round-start-rating="false"
                                        :star-size="20")
                                    .text-sm.align-center ({{ especialista.nr_avaliacao }})


                            .p-1.w-full
                                //.my-2(v-if='especialista.ds_observacao')
                                //    p.text-sm.font-bold.text-gray-600 <i class='jam jam-info'></i> Observações
                                //    .text-sm.whitespace-pre-wrap {{ especialista.ds_observacao }}
                                div(v-if='! $parent.agendamento.especialidade')
                                    p.text-sm.font-bold.text-gray-600 <i class='jam jam-medical'></i> Especialidades
                                    .pr-1.pt-1.inline-block(v-for='especialidade, idx in especialista.cd_especialidade' :key='idx')
                                        b-tag(type='is-primary' style='background-color:#1aa6a5 !important') {{ especialidade.nm_especialidade }}
                                div(v-if='especialista.cd_tag_area_atuacao.length')
                                    p.text-sm.font-bold.text-gray-600 <i class='jam jam-activity'></i> Área de Atuação
                                    .pr-1.pt-1.inline-block(v-for='area, idx in especialista.cd_tag_area_atuacao' :key='idx')
                                        b-tag(type='is-primary' style='background-color:#1aa6a5 !important') {{ area.ds_area_atuacao }}

        b-modal(v-model='dialog.selectEspecialidade' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal)
            template(#default='props')
                .modal-card.max-w-sm.p-4(class='md:p-0')
                    header.modal-card-head
                        p.modal-card-title.text-sm.font-bold Selecione especialidade
                        button.delete(type='button' @click='props.close()')
                    section.modal-card-body
                        p.text-sm.text-center Por qual especialidade quer ser atendido?
                    footer.modal-card-foot.flex.flex-wrap.justify-center.p-1
                        .p-1.w-full(v-for='especialidade, idx in selectedEspecialista.cd_especialidade' :key='idx')
                            b-button.text-sm.w-full(type='is-primary' @click='selectEspecialidade(especialidade)') {{ especialidade.nm_especialidade }}

        b-modal.sm(v-model='dialog.maisFiltros' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal v-if="! waiting.especialistas" )
            template(#default='props')
                .modal-card.p-4(class='md:p-0 ')
                    header.modal-card-head
                        p.modal-card-title.text-sm.font-bold Sintomas e Queixas
                        button.delete(type='button' @click='props.close(); filtrarSintoma()')
                    section.modal-card-body
                        b-input(
                            placeholder="Pesquisar",
                            icon="magnify",
                            v-model="filter.ds_area_atuacao",
                            @input="getAreasdeAtuacao()",
                        )
                        .flex.flex-wrap.pb-4
                            .p-1.w-full.text-center(v-if="!$parent.agendamento.list_Sintomas.length")
                                p.text-sm.my-6 Nenhum sintoma encontrado.

                        .w-full.shadow-md.border-b.my-1(
                            v-for="sintoma in $parent.agendamento.list_Sintomas",
                            :key="sintoma.id"
                        )

                            .flex.items-center.bg-white.border.border-b-0.cursor-pointer(
                                class="hover:bg-gray-100",
                                @click="selectSintoma(sintoma.id, $event)"
                            )
                                .wrapper.p-2.w-full.flex.items-center
                                    b-checkbox.b-checkbox-exames.w-full(

                                        v-model="selectedSintomas",
                                        :id="`selectedSintomas{sintoma.id}`",
                                        :native-value="sintoma"
                                    )
                                        .w-full.flex.flex-wrap.justify-between.items-center
                                            .w-full
                                                p.font-bold.w-full {{ sintoma.ds_area_atuacao }}
                                                a.text-xs.text-blue-700(
                                                    class="hover:text-blue-700 hover:underline",
                                                    href="javascript:;",
                                                    @click="toggleSinonimo(sintoma)"
                                                )
                    footer.modal-card-foot.flex.flex-wrap.justify-center.p-1
                        .p-1.w-full
                            b-button.rounded.p-2.h-auto(
                                type="is-ghost",
                                class="",
                                @click="selectedSintomas = []"
                            )
                                .flex.items-center
                                    .text-sm.font-bold LIMPAR FILTRO(S)
                            b-button.rounded.p-2.h-auto(
                                type="is-primary",
                                class="",
                                @click="filtrarSintoma()"
                            )
                                .flex.items-center
                                    .text-sm.font-bold FILTRAR


</template>

<script>
import { Agendamento } from "../../../middleware";
import SelecionarSintomas from "@/components/Agendamento/Etapas/SelecionarSintomas";
import StarRating from "vue-star-rating";
import _ from "lodash";

export default {
  components: { StarRating, SelecionarSintomas },
  created() {
    const pesquisar_por = this.$parent.pesquisarPor;

    /* Explanation: this piece of code should be added because you can pass a doctor_name with only id, so the user will select the desired speciality */
    if (pesquisar_por === "medico" && this.$route.params.slug) {
      if (this.dialog.maisFiltros) this.dialog.maisFiltros = false;
      this.waiting.especialistas = true;
      let params = {
        ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
      };
      if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))



      // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
      if (!params.ie_telemedicina) params.cd_cidade = this.$store.state.cdCidade;
     
      //Filtrar dentistas caso o componente pai seja Odonto.vue
      if (this.$parent.agendamento.is_odonto) {
        params.is_odonto = true;
      }
      if (this.$parent.agendamento.sintomas?.length) {
        params.id_tag = this.$parent.agendamento.sintomas.join(",");
      }
      if (this.nm_especialista) {
        params.nm_especialista = this.nm_especialista;
        this.$store.commit("setBuscaEspecialista", this.nm_especialista);
        // console.log("especialista", this.$store.state.buscaEspecialista);
      }
      if (this.$parent.agendamento.especialidade) params.cd_especialidade = this.$parent.agendamento.especialidade.id;
      let mem = ++this.requestCounter;

      const [slug_nm_medico, slug_id_medico, slug_id_especialidade, slug_date] = this.$route.params.slug.split("_");

      if (slug_nm_medico && slug_id_medico && !slug_id_especialidade && !slug_date) {
        Agendamento.getEspecialistas2(params).then((response) => {
          if (mem === this.requestCounter) {
            this.waiting.especialistas = false;
            if (response.status === 200) {
              this.list = response.data?.filter((doctor) => doctor?.id == slug_id_medico);
              this.list.forEach((item) => {
                item.mostrandoDetalhes = false;
              });
            }
          }
        });
      } else {
        this.getEspecialistas();
      }
    } else {
      this.getEspecialistas();
    }

    this.getAreasdeAtuacao();
  },
  // beforeDestroy() {
  //   this.$store.commit("setBuscaEspecialista", null);
  // },
  data() {
    return {
      selectedSintomas: [],
      selectedEspecialista: null,
      filter: { ds_area_atuacao: null },
      dialog: { selectEspecialidade: false, maisFiltros: false },
      waiting: { especialistas: true, sintomas: true },
      nm_especialista: this.$store.state.buscaEspecialista,
      requestCounter: 0,
      page: 1,
      isTypingLocal: false,
    };
  },
  watch: {
    nm_especialista: _.debounce(function() {
      this.isTypingLocal = false;
    }, 500),
    isTypingLocal: function(value) {
      if (!value) {
        // console.log("IS TYPE", this.isTypingLocal)
        this.applyFilter();
      }
    },
  },

  methods: {
    back() {
      this.$store.commit("setBuscaEspecialista", null);
      //O botão de volar muda caso seja usado pelo componente Odonto.vue
      let ds_telemedicina = this.$parent.agendamento.ie_telemedicina ? "por-video" : "presencial";
      let pesquisar_por = this.$parent.pesquisarPor;
      this.$parent.agendamento.ie_hora_marcada = null;
      if (this.$parent.pesquisarPor === "medico") {
        this.$parent.pesquisarPor = null;
        if (this.$parent.agendamento.sintomas) {
          this.$parent.agendamento.sintomas = null;
          this.$parent.agendamento._selectedSintomas = null;
        }
        if (this.$parent.agendamento.is_odonto) this.$router.push({ name: "agendarOdonto" });
        else this.$router.push({ name: "agendarConsulta" });
      }
      if (this.$parent.pesquisarPor === "especialidade") {
        this.$parent.pesquisarPor = pesquisar_por;
        this.$parent.agendamento.especialidade = null;
        this.$parent.agendamento.sintomas = null;
        if (this.$parent.agendamento.is_odonto)
          this.$router.push({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por } });
        else this.$router.push({ name: "agendarConsultaCompartilhar", params: { ds_telemedicina, pesquisar_por } });
      }
      if (this.$parent.pesquisarPor === "sintoma") {
        this.$parent.pesquisarPor = pesquisar_por;
        this.$parent.agendamento.especialista = null;
        this.$parent.agendamento.sintomas = null;
        if (this.$parent.agendamento.is_odonto)
          this.$router.push({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por } });
        else this.$router.push({ name: "agendarConsultaCompartilhar", params: { ds_telemedicina, pesquisar_por } });
      }
    },
    getEspecialistas() {
      if (this.dialog.maisFiltros) this.dialog.maisFiltros = false;
      this.waiting.especialistas = true;
      let params = {
        ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
      };
      if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

      // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
      if (!params.ie_telemedicina) params.cd_cidade = this.$store.state.cdCidade;

      //Filtrar dentistas caso o componente pai seja Odonto.vue
      if (this.$parent.agendamento.is_odonto) {
        params.is_odonto = true;
        
      }
      if (this.$parent.agendamento.sintomas?.length) {
        params.id_tag = this.$parent.agendamento.sintomas.join(",");
      }
      if (this.nm_especialista) {
        params.nm_especialista = this.nm_especialista;
        this.$store.commit("setBuscaEspecialista", this.nm_especialista);
        // console.log("especialista", this.$store.state.buscaEspecialista);
      }
      if (this.$parent.agendamento.especialidade) params.cd_especialidade = this.$parent.agendamento.especialidade.id;
      let mem = ++this.requestCounter;

      Agendamento.getEspecialistas2(params).then((response) => {
        if (mem === this.requestCounter) {
          this.waiting.especialistas = false;
          if (response.status === 200) {
            this.list = response.data;
            this.list.forEach((item) => {
              item.mostrandoDetalhes = false;
            });
          }
        }
      });
    },
    getAreasdeAtuacao() {
      console.log("especialidade", this.$parent.agendamento.especialidade);
      this.waiting.sintomas = false;
      let params = {
        ie_tipo_procedimento: this.$parent.agendamento.ie_tipo_agenda,
        is_odonto: this.$parent.agendamento.is_odonto ? true : false,
      };

      if (this.filter.ds_area_atuacao) {
        params.ds_area_atuacao = this.filter.ds_area_atuacao;
        params.page = 1;
        this.page = 1;
        this.lockPage = false;
      }

      Agendamento.getAreadeAtuacao(params).then((response) => {
        this.waiting.sintomas = false;
        this.waiting.nextPage = false;
        if (response.status === 200) {
          if (response.data.length || this.filter.ds_area_atuacao) {
            if (this.page === 1) this.$parent.agendamento.list_Sintomas = response.data;
            else this.$parent.agendamento.list_Sintomas = this.$parent.agendamento.list_Sintomas.concat(response.data);
          } else {
            this.lockPage = true;
          }
        }
      });
    },
    filtrarSintoma() {
      this.$parent.agendamento._selectedSintomas = this.selectedSintomas;
      this.$parent.agendamento.sintomas = this.selectedSintomas.map((x) => x.id);
      this.getEspecialistas();
    },
    selectSintoma(id, ev) {
      if (ev.target.classList.contains("wrapper")) {
        let el = document.getElementById(`selectedSintomas${id}`);
        el.click();
      }
    },
    selectEspecialista(especialista, ev) {
      if (ev.target.id !== "btnExibirDetalhes") {
        this.selectedEspecialista = especialista;
        if (this.$parent.agendamento.especialidade) {
          this.selectEspecialidade(this.$parent.agendamento.especialidade);
        } else {
          if (especialista.cd_especialidade.length > 1) this.dialog.selectEspecialidade = true;
          else this.selectEspecialidade(especialista.cd_especialidade[0]);
        }
      }
    },
    selectEspecialidade(especialidade) {
      let ds_telemedicina = this.$parent.agendamento.ie_telemedicina ? "por-video" : "presencial";

      this.$parent.agendamento.especialista = this.selectedEspecialista;
      this.$gtag.event("especialista_selecionado", { especialista: this.selectedEspecialista });

      this.$parent.agendamento.especialidade = especialidade;
      let slug =
        this.selectedEspecialista.nm_especialista.replace(/\s/g, "-").toLowerCase() +
        "_" +
        this.selectedEspecialista.id +
        "_" +
        especialidade.id;
      let pesquisar_por = this.$parent.pesquisarPor;
      if (pesquisar_por === "sintoma") {
        pesquisar_por = "medico";
      }
      //Caso seja Odonto, a penúltima etapa recebe parâmetros diferentes
      if (!this.$parent.agendamento.is_odonto) {
        this.$router.push({ name: "agendarConsultaCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
      } else this.$router.push({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
    },
    applyFilter() {
      this.getEspecialistas();
    },
    limpar() {
      this.nm_especialista = null
      var searchField = document.getElementById("search-especialista");
      searchField.focus()
      this.applyFilter()

    }
  },
};
</script>
