<template lang="pug">
  //- O componente Exames é utilizado tanto para mostrar exames quanto procedimentos e chekups

  .w-full
    .absolute.top-0.left-0.p-2
        a.text-3xl(@click='back()')
            i.jam.jam-chevron-left

    .w-full
      .pt-4.pb-3.text-center.w-full
        .px-8.mb-2
          span.text-gray-600.text-xs(
            v-if="$parent.agendamento.ie_tipo_agenda === 'C'"
          ) {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
        h1.font-bold.text-medclub-blue-300.text-sm Escolha {{ isMultiSelect ? 'pelas Áreas' : 'pela Área' }} de Atuação

      .p-1(
        v-if="!mostrarSelecionados && $parent.agendamento.ie_tipo_agenda !== 'CK'"
      )
        b-input(
          placeholder="Pesquisar",
          icon="magnify",
          v-model="filter.ds_area_atuacao",
          @input="getAreasdeAtuacao()"
        )

      .p-3(v-if="list.length && isMultiSelect")
        .w-full.flex.items-center
          b-switch(v-model="mostrarSelecionados")
            p.text-xs Mostrar itens selecionados

      .py-56(v-if="waiting.sintomas")
        b-notification.p-8.bg-white(:closable="false")
          b-loading(:is-full-page="false", v-model="waiting.sintomas")

      div(v-else)
        .flex.flex-wrap.pb-4
          .p-1.w-full.text-center(v-if="!list.length")
            p.text-sm.my-6 Nenhum sintoma {{ mostrarSelecionados ? 'selecionado' : 'encontrado' }}.

          .p-1.w-full(v-else-if="mostrarSelecionados")
            div(v-if="selected.length")
              .w-full.shadow-md.border-b(
                v-for="sintoma in selected",
                :key="sintoma.id"
              )
                .flex.items-center.bg-white.border.border-b-0.cursor-pointer(
                  @click="select(sintoma.id, $event)"
                )
                  .wrapper.p-2.w-full.flex.items-center
                    b-checkbox(
                      v-model="selected",
                      :id="`selected${sintoma.id}`",
                      :native-value="sintoma"
                    )
                      span.text-sm.text-gray-700 {{ sintoma.ds_area_atuacao }}
            .p-2(v-else)
              p.text-sm.text-center.text-gray-600 Você ainda não selecionou nenhum item.

          .p-1.w-full(v-else)
            .w-full.shadow-md.border-b.my-1(
              v-for="sintoma in list",
              :key="sintoma.id"
            )
              .flex.items-center.bg-white.border.border-b-0.cursor-pointer(
                class="hover:bg-gray-100",
                @click="select(sintoma.id, $event)"
              )
                .wrapper.p-2.w-full.flex.items-center
                  b-checkbox.b-checkbox-exames.w-full(

                    v-model="selected",
                    :id="`selected${sintoma.id}`",
                    :native-value="sintoma"
                  )
                    .w-full.flex.flex-wrap.justify-between.items-center
                      .w-full
                        p.font-bold.w-full {{ sintoma.ds_area_atuacao }}
                        a.text-xs.text-blue-700(
                          class="hover:text-blue-700 hover:underline",
                          href="javascript:;",
                          @click="toggleSinonimo(sintoma)"
                        )

          .py-2.w-full.flex.justify-center(v-if="waiting.nextPage")
            b-notification.p-8.bg-white(:closable="false")
              b-loading(
                :is-full-page="false",
                v-model="waiting.nextPage"
              )

          .w-full.bottom-0.left-0.right-0.bg-white.p-4.text-center.sticky(
            :class="! mostrarSelecionados && {'fixed': true}"
            v-if="selected.length",
          )
            b-button.w-full.rounded.p-2.h-auto(
              type="is-primary",
              class="md:max-w-xs",
              @click="concluir()"
            )
              .flex.items-center
                .text-sm CONTINUAR

</template>

<style lang="scss">
.b-checkbox-exames .control-label {
  width: 100%;
}
</style>

<script>
import { Agendamento } from "../../../middleware";
export default {
  props: ['isOdonto'],
  created() {
    this.$parent.$parent.$parent.hideFooter = false;
    if (this.$parent.agendamento._selectedSintomas) {
      this.selected = this.$parent.agendamento._selectedSintomas;
      delete this.$parent.agendamento._selectedSintomas;
    }
    this.getAreasdeAtuacao();
  },
  watch: {
    selected: function (val) {
      if (val.length) this.$parent.$parent.$parent.hideFooter = true;
      else this.$parent.$parent.$parent.hideFooter = false;
    },
  },
  // mounted() {
  //   window.onscroll = () => {
  //     if (
  //         window.innerHeight + window.scrollY >=
  //         document.body.offsetHeight - 200
  //     ) {
  //       if (
  //           !this.waiting.nextPage &&
  //           this.$parent.agendamento.ie_tipo_agenda === "EL" &&
  //           !this.filter.ds_area_atuacao
  //       ) {
  //         this.page++;
  //         this.getAreasdeAtuacao();
  //       }
  //     }
  //   };
  // },
  data() {
    return {
      list: [],
      selected: [],
      lockPage: false,
      mostrarSelecionados: false,
      waiting: { sintomas: true, nextPage: false },
      filter: { ds_area_atuacao: null },
      page: 1,
      mostrarSinonimos: [],
    };
  },
  computed: {
    //Caso o componente permita selecionar mais de um procedimento ao mesmo tempo
    isMultiSelect() {
      return ["EL"].includes(
          this.$parent.agendamento.ie_tipo_agenda
      );
    },
  },
  methods: {
      back () {
          //O botão de volar muda caso seja usado pelo componente Odonto.vue
          this.$parent.agendamento.ie_hora_marcada = null

          if (this.$parent.pesquisarPor === 'sintoma'){
              this.$parent.pesquisarPor = null
              if (this.$parent.agendamento.sintomas) {
                  this.$parent.agendamento.sintomas = null
                  this.$parent.agendamento._selectedSintomas = null
              }
              if(this.$parent.agendamento.is_odonto)
                  this.$router.push({ name: 'agendarOdonto' })
              else this.$router.push({ name: 'agendarConsulta' })
          }
      },
    getAreasdeAtuacao() {
      if (this.mostrarSelecionados) return 0;
      if (!this.filter.ds_area_atuacao && this.lockPage) return 0;
      if (this.page === 1 && !this.filter.ds_area_atuacao)
        this.waiting.sintomas = true;
      else this.waiting.nextPage = true;

      let params = {
        ie_tipo_procedimento: this.$parent.agendamento.ie_tipo_agenda,
        is_odonto : !!this.isOdonto
      };
      if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))



      if (this.filter.ds_area_atuacao) {
        params.ds_area_atuacao = this.filter.ds_area_atuacao;
        params.page = 1;
        this.page = 1;
        this.lockPage = false;
      }

      Agendamento.getAreadeAtuacao(params).then((response) => {
        this.waiting.sintomas = false;
        this.waiting.nextPage = false;
        if (response.status === 200) {
          if (
              response.data.length ||
              this.filter.ds_area_atuacao
          ) {
            if (this.page === 1) this.list = response.data;
            else this.list = this.list.concat(response.data);
          } else {
            this.lockPage = true;
          }
        }
      })

    },
    select(id, ev) {
      if (ev.target.classList.contains("wrapper")) {
        let el = document.getElementById(`selected${id}`);
        el.click();
      }
    },
    concluir() {
      this.$parent.$parent.$parent.hideFooter = false;
      this.$parent.agendamento._selectedSintomas = this.selected;
      this.$parent.agendamento.sintomas = this.selected.map(x => x.id);
    },
    toggleSinonimo(sintoma) {
      if (this.mostrarSinonimos.includes(sintoma.id))
        this.mostrarSinonimos = this.mostrarSinonimos.filter(
            (id) => id !== sintoma.id
        );
      else this.mostrarSinonimos.push(sintoma.id);
    },
  },
};
</script>