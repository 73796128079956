<template lang="pug">
    .w-full
        div
            .absolute.top-0.left-0.p-2
                a.text-3xl(@click='back()')
                    i.jam.jam-chevron-left
            .py-56(v-if='waiting.datas')
                b-notification.p-8.bg-white(:closable='false')
                    b-loading(:is-full-page='false' v-model='waiting.datas')
            .w-full(v-show='! waiting.especialistas && ! waiting.datas')
                .py-24.px-4(v-if='! dateList.length')
                    a.bg-green-500.border.border-yellow-300.flex.max-w-xl.mx-auto.items-center.rounded(href="https://api.whatsapp.com/send?phone=558631311881" style="text-decoration: none; color: #fff")
                        .text-center(class='w-1/6')
                            b-icon(icon='whatsapp' size="is-medium" )
                        .p-2(class='w-5/6')
                            span.text-sm(v-if="$parent.agendamento.retonar_por_video") Não há nenhuma data disponível para <b>{{ $parent.agendamento.ie_telemedicina ? 'consulta por vídeo' : 'consulta presencial' }} </b>
                            span.text-sm(v-else) Não há nenhuma data disponível para <b> consulta </b>
                            span.text-sm(v-if='$parent.agendamento.especialista') com o profissional <b>{{ $parent.agendamento.especialista.nm_especialista }}</b>
                            span.text-sm(v-else-if='$parent.agendamento.especialidade') na especialidade <b>{{ $parent.agendamento.especialidade.nm_especialidade }}</b>
                            span.text-sm . Por favor, entre em contato com o MEDCLUB através do nosso whatsapp <b>(86) 3131-1881</b>

                div(v-else)
                    .py-56(v-if='waiting.transferirHorario')
                        b-notification.p-8.bg-white(:closable='false')
                            b-loading(:is-full-page='false' v-model='waiting.transferirHorario')
                    div(v-else)
                        .w-full.flex.flex-wrap.justify-end.px-2(class='py-4 md:py-5')
                            .text-right(class='w-4/6 md:w-full')
                                h1.text-gray-700
                                    span {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
                                    span(v-if='$parent.agendamento.estabelecimento && ! $parent.agendamento.ie_telemedicina') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.estabelecimento.nm_estabelecimento || $parent.agendamento.estabelecimento.nm_fantasia }}
                                    span(v-if='$parent.agendamento.especialidade') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialidade.nm_especialidade }}
                                    span(v-if='$parent.agendamento.especialista') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialista.nm_especialista }}
                div(v-if="dateList.length" )
                    .pb-3.text-center.w-full
                        h1.font-bold.text-gray-700.text-md(v-if="$route.name !== 'prontoSocorro'") Escolha data e {{ $parent.agendamento.ie_hora_marcada ? 'horário' : 'especialista' }}
                        h1.font-bold.text-gray-700.text-md(v-else) Escolha {{ $parent.agendamento.ie_hora_marcada ? 'horário' : 'especialista' }}

                    .flex.w-full.overflow-x-auto.px-1.py-2.bg-gray-100.shadow-sm.rounded.border(v-if="$route.name !== 'prontoSocorro'")
                        .p-1(v-for='date, idx in dateList' :key='idx')
                            .w-16.rounded.overflow-hidden.border.cursor-pointer.bg-white(@click='data = date; getEspecialistas()'
                                :class="{ 'estabelecimento-data--date-selected': $root.moment(date).format('YYYY-MM-DD') === $root.moment(data).format('YYYY-MM-DD') }")
                                .w-full.bg-red-600.text-center
                                    div.text-white.text-xs {{ $root.moment(date).format('MMM') }}
                                .w-full.text-center.pt-1
                                    div.text-md.font-bold {{ $root.moment(date).format('DD') }}
                                .w-full.text-center
                                    div.text-xs {{ $root.moment(date).format('ddd') }}

                    .pt-4.pb-3.text-center.w-full(v-if='! waiting.datas')
                        h1.font-bold.text-gray-700.text-md {{ $root.dataPorExtenso($root.moment(data, 'DD/MM/YYYY', true).format('YYYY-MM-DD')) }}
                    .p-2.pb-16(v-if='! waiting.especialistas')
                        h1.px-2.text-gray-700(v-if='list.length > 1') Selecione o {{ $parent.agendamento.ie_hora_marcada ? 'horário' : 'especialista' }}:
                        .p-1.w-full.text-center(v-if='! list.length')
                            p.text-sm.my-6 Nenhum horário encontrado.
                        .p-1.w-full(v-else v-for='especialista, idx in list' :key='idx')
                            .p-4.shadow-md.rounded.border

                                .flex.items-center

                                    .p-1.hidden(class='md:block')
                                        .w-16.h-16.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(:style="`background-image: url('${ especialista.aq_foto }')`")

                                    .w-full(class='md:w-11/12 md:pl-4 md:p-1')
                                        .flex.flex-wrap.w-full
                                            .w-full(class='md:text-left md:w-5/6')
                                                .mx-auto.mb-2.w-16.h-16.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(class='md:hidden' :style="`background-image: url('${ especialista.aq_foto }')`")
                                                p.text-sm.font-bold.text-gray-700(class='md:text-lg') {{ especialista.nm_especialista }}
                                                p.text-sm.text-gray-600(v-if="$route.name !== 'prontoSocorro'") {{ especialista.nm_conselho }} {{ especialista.uf_conselho }} - {{ especialista.nr_conselho }}
                                                .flex.items-center(v-if="especialista.nr_avaliacao")
                                                    star-rating(
                                                        :rating="especialista.nr_avaliacao"
                                                        :read-only="true"
                                                        :show-rating="false"
                                                        :round-start-rating="false"
                                                        :star-size="20")
                                                    .text-sm.align-center ({{ especialista.nr_avaliacao }})

                                            .w-full(class='text-center md:text-right md:w-1/6')
                                                p.font-bold.text-medclub-blue-400.text-xl(class='md:text-2xl') {{ $root.formatPrice(especialista.nr_valor) }}
                                                // p.font-bold.text-xs.text-red-600 {{ especialista.qtd_vaga }} VAGA{{ especialista.qtd_vaga === 1 ? '' : 'S' }}

                                .w-full.my-2(v-if='especialista.ds_observacao')
                                    p.text-sm.font-bold.text-medclub-blue-400.mb-2 Observações
                                    .text-sm.text-gray-700.whitespace-pre-wrap(v-html='especialista.ds_observacao')
                                .mt-2.mb-4.text-xs.bg-yellow-200.p-2.border.border-yellow-300.w-full(v-if='especialista.ie_agenda_presencial') <b>Atenção!</b> É necessário agendar com a clínica.

                                .w-full.border-t.mt-2.pt-2(v-if='especialista.ie_hora_marcada && !especialista.ie_agenda_presencial')
                                    p.text-sm.text-gray-700.font-bold Escolha o horário:
                                        .pr-1.pt-1.inline-block(v-for='horario, idx in especialista.hr_horario' :key='idx')
                                            b-tag.p-4.font-bold.cursor-pointer(type='is-primary' style='background-color:#1aa6a5 !important' @click.native='selectHorario(horario, especialista)') {{ horario.hr_agenda }}

                                .w-full.border-t.mt-2.pt-2(v-else)
                                    div(v-if="$route.name !== 'prontoSocorro'")
                                        p.text-xs.text-bold(v-if="!especialista.ie_hora_marcada") POR ORDEM DE CHEGADA
                                        div(v-if="especialista.hr_turnos")
                                            div(v-for="horario, idx in especialista.hr_turnos")
                                                p.text-medclub-red-400.font-bold.text-sm A partir de {{ horario.hr_inicio_atendimento }}
                                        div(v-else)
                                           p.text-medclub-red-400.font-bold.text-sm A partir de {{ especialista.hr_horario[0].hr_inicio_atendimento }}
                                            //- p.text-medclub-blue-400.font-bold.text-sm(v-if="$route.name !== 'prontoSocorro' && !especialista.hr_turnos")
                                            //-     p.text-medclub-red-400.font-bold.text-sm A partir de {{ especialista.hr_horario[0].hr_inicio_atendimento }}
                                    .w-full.pt-4.text-center
                                        b-button.text-sm.w-full(class='md:w-auto' type='is-primary' icon-pack='jam' icon-left='jam-shopping-cart' @click='selectHorario(especialista.hr_horario[0], especialista)') Adicionar ao carrinho
</template>

<script>
import { Agendamento } from "../../../middleware";
import StarRating from "vue-star-rating";
import { mergeArray } from "../../../utils";
import moment from "moment";

export default {
  components: { StarRating },
  created() {
    if (!["medico", "reagendamento", "retorno"].includes(this.$parent.pesquisarPor)) {
      this.$parent.agendamento.especialista = null;
    }

    let params = {
      cd_especialidade: this.$parent.agendamento.especialidade.id,
      ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
    };
    if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))



    if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;

    if (!["reagendamento", "retorno"].includes(this.$parent.pesquisarPor)) {
      if (this.$parent.agendamento.especialista && !this.$parent.agendamento.ie_telemedicina)
        this.$parent.agendamento.estabelecimento = null;
    }

    if (this.$parent.agendamento.estabelecimento && this.$parent.agendamento.ie_telemedicina == false)
      params.cd_estabelecimento = this.$parent.agendamento.estabelecimento.id;
    else params.cd_estabelecimento = 13;

    this.waiting.datas = true;

    Agendamento.getEspecialistasDatas(params).then((response) => {
      this.waiting.datas = false;
      if (response.status === 200) {
        let datas = response.data.datas;
        let dt_agenda = this.$root.moment(this.$parent.agendamento.dt_agenda).format("YYYY-MM-DD");
        let dt_execucao = this.$parent.agendamento.guiaeletronica_set?.dt_execucao;
        if (dt_execucao) dt_execucao = this.$root.moment(dt_execucao).format("YYYY-MM-DD");
        if (datas) {
          datas.forEach((data) => {
            if (this.$parent.pesquisarPor === "retorno") {
              if (this.$root.moment(data) < this.$root.moment(dt_execucao).add(30, "d")) {
                this.dateList.push(this.$root.moment(data)._d);
              }
            } else if (
              (this.$parent.pesquisarPor === "reagendamento" || dt_execucao != data) &&
              (this.$parent.agendamento.ie_hora_marcada || dt_agenda != data)
            ) {
              this.dateList.push(this.$root.moment(data)._d);
            }
          });
         
          this.data = this.$parent.agendamento._data ? this.$parent.agendamento._data : this.dateList[0];
        }
      }
      this.getEspecialistas();
    });
  },
  data() {
    return {
      list: [],
      data: null,
      dateList: [],
      transferirHorario: false,
      waiting: { especialistas: true, datas: true, transferirHorario: false },
      dialog: { prontoSocorro: false },
      termoAceito: false,
      horario: "",
      especialista: "",
    };
  },
  methods: {
    back() {
      delete this.$parent.agendamento._data;
      if (this.$parent.pesquisarPor === "reagendamento" || this.$parent.pesquisarPor === "retorno") {
        this.$parent.agendamento = null;
        this.$parent.pesquisarPor = null;
      } else if (this.$parent.agendamento.ie_telemedicina) {
        this.$parent.agendamento.especialidade = null;
        if (this.$route.name !== "agendarConsulta") this.$router.push({ name: "agendarConsulta" });
      } else this.$parent.agendamento.estabelecimento = null;
    },
    listaTeste(arr) {
      // console.log(arr.hr_horario);
      let teste = mergeArray(arr.hr_horario, "hr_inicio_atendimento");

      return teste;
    },
    getEspecialistas() {
      // eslint-disable-next-line no-unused-vars
      let params = {}
      params.dt_consulta = moment().format('YYYY-MM-DD')
      params.cd_especialidade = this.$parent.agendamento.especialidade.id
      if(this.$route.name !== "prontoSocorro"){
        const [slug_doctor_name, slug_doctor_id, slug_id_especialidade] = this.$route.params.slug.split("_");

        params = {
          cd_especialidade: this.$parent.agendamento.especialidade.id,
          ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
          dt_consulta: this.$root.moment(this.data).format("YYYY-MM-DD"),
        };

      const pesquisar_por = this.$route.params.pesquisar_por;

      if (pesquisar_por == "medico") {
        const ds_telemedicina = this.$route.params.ds_telemedicina;
        const slug =
          slug_doctor_name +
          "_" +
          slug_doctor_id +
          "_" +
          slug_id_especialidade +
          "_" +
          this.$root.moment(this.data).format("YYYY-MM-DD"); // slug should have cd_especialidade, cd_estabelecimento

        if (!this.$parent.agendamento.is_odonto) {
          this.$router.replace({
            name: "agendarConsultaCompartilhar",
            params: { ds_telemedicina, pesquisar_por, slug },
          });
        } else {
          this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
        }
      }

      if (pesquisar_por == "data") {
        const cd_especialidade = this.$parent.agendamento.especialidade.id;
        const cd_estabelecimento = this.$parent.agendamento.estabelecimento.id;
        const estabelecimento_name = this.$parent.agendamento.estabelecimento.nm_estabelecimento
          ? String(this.$parent.agendamento.estabelecimento.nm_estabelecimento)
              .replace(/\s/g, "-")
              .toLowerCase()
          : slug_doctor_name;
        const ds_telemedicina = this.$route.params.ds_telemedicina;
        const slug =
          estabelecimento_name +
          "_" +
          cd_especialidade +
          "_" +
          cd_estabelecimento +
          "_" +
          this.$root.moment(this.data).format("YYYY-MM-DD"); // slug should have cd_especialidade, cd_estabelecimento

        if (!this.$parent.agendamento.is_odonto) {
          this.$router.replace({
            name: "agendarConsultaCompartilhar",
            params: { ds_telemedicina, pesquisar_por, slug },
          });
        } else {
          this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
        }
      }
      }

      if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER")) 

      if (this.$parent.agendamento.estabelecimento && this.$parent.agendamento.ie_telemedicina)
        params.cd_estabelecimento = 13;
      else if (this.$parent.agendamento.estabelecimento && this.$parent.agendamento.ie_telemedicina == false)
        params.cd_estabelecimento = this.$parent.agendamento.estabelecimento.id;
      if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;

      this.waiting.especialistas = true;
      Agendamento.getEspecialistas(params).then((response) => {
        this.waiting.especialistas = false;
        if (response.status === 200) {
          let result = Object.values(response.data)[0];
          if (result.length) {
            result.map((item) => {
              if (!item.ie_hora_marcada && !item.ie_agenda_presencial) {
                item["hr_turnos"] = mergeArray(item.hr_horario, "hr_horario_funcionamento");
              }
            });
            this.list = result;
          } else {
            this.list = [];
          }

          // this.list = Object.values(response.data).length ? Object.values(response.data)[0] : []
        }
      });
    },
    selectHorario(horario, especialista) {
      this.horario = horario;
      this.especialista = especialista;
      // Obs 1: Caso seja pronto socorro, primeiro deve aparecer o modal de
      // termos e condições, antes da pessoa poder adicionar ao carrinho
      // Obs 2: O modal é exibido apenas caso exista um termo para aquele estabelecimento
      if (this.$route.name === "prontoSocorro" && this.termoAceito === false && especialista.ds_termo_pronto_socorro) {
        this.$buefy.dialog.confirm({
          title: "Termos e Condições",
          message: `<div class="text-gray-700 whitespace-pre-wrap">${this.especialista.ds_termo_pronto_socorro}</div>`,
          confirmText: "Aceito",
          cancelText: "Cancelar",
          onConfirm: () => {
            this.termoAceito = true;
            this.selectHorario(horario, especialista);
          },
        });
      } else {
        let message = "";
        if (this.especialista.ie_hora_marcada) {
          message += "Horário: ";
          message += this.horario.hr_agenda;
        } else {
          message += "A partir de ";
          message += this.especialista.hr_horario[0].hr_inicio_atendimento;
        }
        if (this.$parent.pesquisarPor === "reagendamento") {
          this.$buefy.dialog.confirm({
            title: "Confirmar reagendamento?",
            //message: `<div class="flex justify-center my-2"><i class="mdi mdi-calendar-sync text-6xl"></i><div class="text-gray-700 whitespace-pre-wrap">${this.especialista.ds_termo_pronto_socorro ? this.especialista.ds_termo_pronto_socorro : ''}</div></div>`,
            message: `
                                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center">

                                        <img src=${
                                          this.especialista.aq_foto
                                        } style="height: 80px; width: 80px; border-radius: 40px" />
                                        <p class="font-bold mt-2 ">Dr(a). ${this.especialista.nm_especialista}</p>
                                        <p>${this.$root.dataPorExtenso(
                                          this.$root.moment(this.data, "DD/MM/YYYY", true).format("YYYY-MM-DD")
                                        )}</p>
                                        <p >${message}</p>

                                </div>`,
            confirmText: "Sim",
            cancelText: "Não",
            onConfirm: () => {
              this.waiting.transferirHorario = true;
              this.transferirHorario = true;
              Agendamento.transferirHorario(this.$parent.agendamento.cd_horario_agendamento, horario.id).then(() => {
                this.waiting.transferirHorario = false;
                this.$router.go();
              });
              this.$parent.pesquisarPor = null;
            },
          });
        } else if (this.$parent.pesquisarPor === "retorno") {
          // <i class="mdi mdi-calendar-sync text-6xl"></i>

          this.$buefy.dialog.confirm({
            title: "Confirmar retorno?",
            // message: `<div class="flex items-center justify-center"><div><div class="w-16 h-16 bg-gray-200 rounded-full bg-center bg-cover bg-no-repeat" style="background-image:url(${this.especialista.aq_foto})"></div></div><div class="flex items-center justify-center text-center text-sm font-bold md:text-left md:w-5/6">Dr(a) ${this.especialista.nm_especialista}</div></div>`,
            message: `
                                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center">

                                        <img src=${
                                          this.especialista.aq_foto
                                        } style="height: 80px; width: 80px; border-radius: 40px" />
                                        <p class="font-bold mt-2 ">Dr(a). ${this.especialista.nm_especialista}</p>
                                        <p>${this.$root.dataPorExtenso(
                                          this.$root.moment(this.data, "DD/MM/YYYY", true).format("YYYY-MM-DD")
                                        )}</p>
                                        <p >${message}</p>

                                </div>`,
            onConfirm: () => {
              this.waiting.transferirHorario = true;
              this.transferirHorario = true;
              Agendamento.marcarRetorno(
                this.$parent.agendamento.cd_horario_agendamento,
                horario.id,
                this.$parent.agendamento.ie_telemedicina,
                this.$parent.agendamento.especialista,
                this.$parent.agendamento.especialidade,
                this.$parent.agendamento.estabelecimento,
                this.$parent.agendamento.paciente
              ).then(() => {
                this.waiting.transferirHorario = false;
                this.$router.go();
              });
              this.$parent.pesquisarPor = null;
            },
          });
        } else {
          this.$parent.agendamento.horario = horario;
          this.$parent.agendamento.especialista = especialista;
          if (!this.$parent.agendamento.estabelecimento)
            this.$parent.agendamento.estabelecimento = { id: especialista.cd_estabelecimento };
          this.$parent.agendamentoCriado = true;
        }
      }
    },
  },
};
</script>
