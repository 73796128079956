<template lang="pug">
.w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='back()')
                i.jam.jam-chevron-left

        .py-56(v-if='waiting.estabelecimentos')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.estabelecimentos')

        .py-56(v-else-if='waiting.datas')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.datas')

        .w-full(v-show='! waiting.estabelecimentos && ! waiting.datas')

            .py-24.px-4(v-if='! dateList.length')

                .bg-yellow-200.border.border-yellow-300.flex.max-w-xl.mx-auto.items-center.rounded
                    .text-center(class='w-1/6')
                        b-icon(icon='alert')
                    .p-2(class='w-5/6')
                        span.text-sm Não há nenhuma data disponível para <b>{{ $parent.agendamento.ie_telemedicina ? 'consulta por vídeo' : 'consulta presencial' }} </b>
                        span.text-sm(v-if='$parent.agendamento.especialista') com o profissional <b>{{ $parent.agendamento.especialista.nm_especialista }}</b>
                        span.text-sm(v-else-if='$parent.agendamento.especialidade') na especialidade <b>{{ $parent.agendamento.especialidade.nm_especialidade }}</b>
                        span.text-sm .
            
            div(v-else)

                .w-full.flex.flex-wrap.justify-end.px-2(class='py-4 md:py-5')
                    .text-right(class='w-4/6 md:w-full')
                        h1.text-gray-700.text-sm
                            span {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
                            span(v-if='$parent.agendamento.especialidade') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialidade.nm_especialidade }}
                            span(v-if='$parent.agendamento.especialista') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialista.nm_especialista }}

                div(v-if='$parent.agendamento.especialidade.id !== 57')
                    .pb-3.text-center.w-full.flex.justify-content-center
                        .flex.align-items-center
                          h1.font-bold.text-gray-700.text-md.mr-1 Escolha data e local
                          a.text-2xl(@click='dialog.maisFiltros=true')
                            i.jam.jam-filter-f(v-if='estabelecimento_disponivel_selecionado')
                            i.jam.jam-filter(v-if='!estabelecimento_disponivel_selecionado')

                    .flex.w-full.overflow-x-auto.px-1.py-2.bg-gray-100.shadow-sm.rounded.border
                        .p-1(v-for='date, idx in dateList' :key='idx')
                            .w-16.rounded.overflow-hidden.border.cursor-pointer.bg-white(@click='data = date; getEstabelecimentos()'
                                :class="{ 'estabelecimento-data--date-selected': $root.moment(date).format('YYYY-MM-DD') === $root.moment(data).format('YYYY-MM-DD') }")
                                .w-full.bg-red-600.text-center
                                    div.text-white.text-xs {{ $root.moment(date).format('MMM') }}
                                .w-full.text-center.pt-1
                                    div.text-md.font-bold {{ $root.moment(date).format('DD') }}
                                .w-full.text-center
                                    div.text-xs {{ $root.moment(date).format('ddd') }}

                .pt-4.pb-3.text-center.w-full(v-if='! waiting.datas')
                    h1.font-bold.text-gray-700.text-md {{ $root.dataPorExtenso($root.moment(data, 'DD/MM/YYYY', true).format('YYYY-MM-DD')) }}
                .p-2.pb-16(v-if='! waiting.estabelecimentos')
                    h1.px-2.text-gray-700(v-if='list.length') Selecione o local:
                    .p-1.w-full.text-center(v-if='! list.length')
                        p.text-sm.my-6 Nenhum horário encontrado.
                    .p-1.w-full(v-else v-for='estabelecimento, idx in estabelecimentos' :key='idx')
                        .p-4.shadow-md.rounded.border(
                            :class="{'cursor-pointer': $parent.pesquisarPor == 'data' }"
                            @click=" if ($parent.pesquisarPor == 'data') selectEstabelecimento(estabelecimento) ")

                            .flex.items-center.p-1

                                .p-1
                                    .w-10.h-10.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(class='md:w-16 md:h-16'
                                        :style="`background-image: url('${ estabelecimento.aq_foto }')`")

                                .p-1(class='w-10/12 s' :class="{ 'w-11/12': $parent.pesquisarPor !== 'data' || estabelecimento.ie_hora_marcada }")
                                    .flex.flex-wrap.w-full
                                        div(class='w-4/6')
                                            p.text-sm.font-bold.text-gray-700(class='md:text-lg') {{ estabelecimento.nm_estabelecimento }}
                                            p.text-xs.text-gray-600.my-1
                                                span {{ estabelecimento.nm_rua }}
                                                span(v-if='estabelecimento.id')
                                                    span , {{ estabelecimento.nr_estabelecimento }}
                                                    span &nbsp;- {{ estabelecimento.nm_bairro }}
                                                    span(v-if='estabelecimento.ds_complemento') &nbsp;- {{ estabelecimento.ds_complemento }}
                                            .flex.items-center(v-if="estabelecimento.nr_avaliacao")
                                                star-rating(
                                                    :rating="estabelecimento.nr_avaliacao"
                                                    :read-only="true"
                                                    :show-rating="false"
                                                    :round-start-rating="false"
                                                    :star-size="20")
                                                .text-sm.align-center ({{ estabelecimento.nr_avaliacao }})
                                            p.text-xs.text-gray-600 {{ $root.applyTelefoneMask(estabelecimento.nr_telefone) }}
                                            .p-1.w-full(v-if='!$parent.agendamento.ie_telemedicina')
                                              map-button(:query='estabelecimento.nm_estabelecimento + " " + estabelecimento.nm_rua + " " + estabelecimento.nr_estabelecimento + " " + estabelecimento.nm_bairro')
                                        .text-right(:class="{ 'hidden': $parent.pesquisarPor !== 'medico' }" class='md:block w-2/6')
                                            p.text-xs.text-gray-700 A PARTIR DE
                                            p.font-bold.text-medclub-blue-400.text-xl(class='md:text-2xl') {{ $root.formatPrice(estabelecimento.nr_valor) }}
                                            p.font-bold.text-xs.text-red-600(v-if="$route.name !== 'prontoSocorro' && !estabelecimento.ie_agenda_presencial") {{ estabelecimento.qtd_vaga }} VAGA{{ estabelecimento.qtd_vaga === 1 ? '' : 'S' }}

                                //text-rig.ht(v-if="$parent.pesquisarPor !== 'medico'" class='w-1/12 md:text-center')
                                //    b-icon.text-3xl(pack='jam' icon='jam-chevron-right')

                            .flex.items-center.w-full.p-2.border-t.mt-2(v-if="$parent.pesquisarPor !== 'medico'" class='md:hidden')
                                .font-bold.text-sm.text-red-600(class='w-1/2' v-if="$route.name !== 'prontoSocorro' && !estabelecimento.ie_agenda_presencial") {{ estabelecimento.qtd_vaga }} VAGA{{ estabelecimento.qtd_vaga === 1 ? '' : 'S' }}
                                .text-right(class='w-1/2')
                                    p.text-xs.text-gray-700 A PARTIR DE
                                    p.font-bold.text-medclub-blue-400.text-xl(class='md:text-2xl') {{ $root.formatPrice(estabelecimento.nr_valor) }}

                            .mt-2.mb-4.text-xs.bg-yellow-200.p-2.border.border-yellow-300.w-full(v-if="$parent.pesquisarPor !== 'data' && estabelecimento.ie_agenda_presencial") <b>Atenção!</b> É necessário agendar com a clínica.
                            div(v-if="$parent.pesquisarPor !== 'data'")
                                .w-full.border-t.mt-2.p-2(v-if="!estabelecimento.ie_agenda_presencial && estabelecimento.ie_hora_marcada")
                                    p.text-xs.text-gray-600(v-if='!estabelecimento.ie_agenda_presencial') POR HORA MARCADA
                                    p.text-sm.text-medclub-blue-300.font-bold Escolha o horário:
                                        .pr-1.pt-1.inline-block(v-for='horario, idx in estabelecimento.hr_horario' :key='idx')
                                            b-tag.p-4.font-bold.cursor-pointer.bg-turquoise(type='is-primary' @click.native='selectEstabelecimento(estabelecimento, horario)') {{ horario.hr_agenda }}
                                .w-full.border-t.mt-2.p-2(v-if='!estabelecimento.ie_hora_marcada')
                                    p.text-xs.text-gray-600(v-if='!estabelecimento.ie_agenda_presencial') POR ORDEM DE CHEGADA
                                    p.text-medclub-blue-400.font-bold.text-sm(v-if='!estabelecimento.ie_agenda_presencial') A partir de {{ estabelecimento.hr_inicio }}
                                    .p-4.pt-0(class='w-full')
                                      .flex.flex-wrap.justify-center(class='w-full')
                                        .p-1(v-if="estabelecimento.ds_observacao && $parent.agendamento.ie_tipo_agenda !== 'CK'" :class="{ 'w-full': ! estabelecimento.ds_orientacao, 'w-1/2': estabelecimento.ds_orientacao }" class='md:w-56')
                                          b-button.w-full.rounded.p-0(type='is-info' style='background-color:#1aa6a5 !important' @click="alert(estabelecimento, 'ds_observacao', 'Observações')")
                                            .flex.items-center
                                              b-icon.text-xl(pack='jam' icon='jam-document')
                                              span.text-sm Observações
                                        .p-1.w-full(class='md:w-56')
                                          b-button.w-full.rounded.p-0(type='is-primary'  @click='selectEstabelecimento(estabelecimento)')
                                            .flex.items-center
                                              b-icon.text-xl(pack='jam' icon='jam-shopping-cart')
                                              span.text-sm Adicionar ao carrinho
                                          

        b-modal.sm(v-model='dialog.maisFiltros' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal v-if="!waiting.estabelecimentos" )
            .modal-card.p-4(class='md:p-0 ')
              header.modal-card-head
                p.modal-card-title Buscar em estabelecimento:
                button.delete(@click='dialog.maisFiltros=false')
              section.modal-card-body
                .flex.rounded-sm.cursor-pointer.mb-4.border-2.p-1(v-for="estabelecimento in estabelecimentosDisponiveis" :key="estabelecimento.id" class="hover:bg-gray-100" @click="filtraPorEstabelecimento(estabelecimento.id)")
                    w-10.h-10.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(class='md:w-12 md:h-12' :style="`background-image: url('${ estabelecimento.aq_foto }')`")
                    flex.col
                      p {{ estabelecimento.nm_estabelecimento }}
                      span {{`${estabelecimento.nm_rua}, ${estabelecimento.nr_estabelecimento}`}}  
                    b-button(v-if="estabelecimento.id != estabelecimento_disponivel_selecionado") Escolher
                    b-button(v-if="estabelecimento.id == estabelecimento_disponivel_selecionado" type="is-primary") Selecionado

                b-button(type="is-warning" @click="clear_filters") Limpar filtros


</template>   

<script>
import { Agendamento } from "../../../middleware";
import StarRating from "vue-star-rating";
import MapButton from "../../global/MapButton.vue";

export default {
  components: { StarRating, MapButton },
  created() {
    this.setup();

    /* const params = {
      cd_especialidade: this.$parent.agendamento.especialidade.id,
      ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
      ie_tipo_agenda: this.$parent.agendamento.ie_tipo_agenda,
      ie_atende_medclub_empresa: localStorage.getItem("MEDCLUB_TYPE_USER") === "true",
    };

    if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;
    this.waiting.datas = true;

    Agendamento.getCalendario(params).then((response) => {
      this.waiting.datas = false;
      if (response.status === 200) {
        let datas = response.data;

        datas.forEach((data) => {
          this.dateList.push(this.$root.moment(data, "YYYY-MM-DD", true)._d);
        });

        this.data = this.$parent.agendamento._data ? this.$parent.agendamento._data : this.dateList[0];
      }
      this.getEstabelecimentos();
    }); */
  },
  computed: {
    estabelecimentos() {
      const estabelecimentosGrupo = this.list.filter((element) => {
        if (element.nr_ordem_listagem) return element;
      });

      const estabelecimentosOutros = this.list.filter((element) => {
        if (!element.nr_ordem_listagem) return element;
      });
      return estabelecimentosGrupo.concat(estabelecimentosOutros);
    },
  },
  data() {
    return {
      list: [],
      data: null,
      dateList: [],
      estabelecimentosDisponiveis: [],
      statusEmpresa: false,
      estabelecimento_disponivel_selecionado: null,
      waiting: { estabelecimentos: true, datas: true },
      dialog: { maisFiltros: false },
    };
  },
  methods: {
    setup(){
      this.dateList = []
      this.list = []
      this.data = null
      this.dialog.maisFiltros = false

      const params = {
      cd_especialidade: this.$parent.agendamento.especialidade.id,
      ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
      ie_tipo_agenda: this.$parent.agendamento.ie_tipo_agenda,
      };

      if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;
      this.waiting.datas = true;

      const urlParams = new URLSearchParams(window.location.search);
      const param_estabelecimento = urlParams.get('estabelecimento')
      if(param_estabelecimento){  
        this.estabelecimento_disponivel_selecionado = param_estabelecimento  
        params.cd_estabelecimento = param_estabelecimento
      }

      Agendamento.getCalendario(params).then((response) => {
        this.waiting.datas = false;
        if (response.status === 200) {
          let datas = response.data;

          datas.forEach((data) => {
            this.dateList.push(this.$root.moment(data, "YYYY-MM-DD", true)._d);
          });

          if (this.$parent.agendamento._data && this.dateList.map(d=> this.$root.moment(d).format("YYYY-MM-DD")).includes(this.$root.moment(this.$parent.agendamento._data).format("YYYY-MM-DD")))
            this.data = this.$parent.agendamento._data;
          else 
            this.data = this.dateList[0];
        }

        this.getEstabelecimentos();
      });
    },
    back() {
      this.$parent.agendamento.ie_hora_marcada = null;
      this.$parent.agendamentoCriado = false;
      if (this.$parent.pesquisarPor === "data") this.$parent.agendamento.especialidade = null;
      if (this.$parent.pesquisarPor === "especialidade") this.$parent.agendamento.especialista = null;
      if (this.$parent.pesquisarPor === "medico") {
        this.$parent.agendamento.especialidade = null;
        this.$parent.agendamento.especialista = null;
      }
      if (this.$parent.pesquisarPor === "sintoma") {
        this.$parent.agendamento.especialidade = null;
        this.$parent.agendamento.especialista = null;
      }
      //O botão de voltar muda caso seja usado pelo componente Odonto.vue
      if (this.$parent.agendamento.is_odonto) {
        this.$router.push({ name: "agendarOdonto" });
      } else {
        //Para o fluxo de prontoSocorro, a tela atual é a primeira, logo se voltar deve ir para o início
        if (this.$route.name === "prontoSocorro") this.$router.push({ name: "home" });
        else if (this.$route.name !== "agendarConsulta") this.$router.push({ name: "agendarConsulta" });
      }
    },
    alert(estabelecimento, property, title) {
      const helpButtonMobile = document.getElementById("helpButtonMobile");
      if (helpButtonMobile) helpButtonMobile.classList.add("hidden");

      this.$buefy.dialog.alert({
        title,
        message: `<div class='whitespace-pre-wrap text-sm'>${estabelecimento[property]}</div>`,
        confirmText: "Entendi",
        onConfirm: () => {
          const helpButtonMobile = document.getElementById("helpButtonMobile");
          if (helpButtonMobile) helpButtonMobile.classList.remove("hidden");
        },
      });
    },
    getEstabelecimentos() {
      let params = {
        cd_especialidade: this.$parent.agendamento.especialidade.id,
        ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
        ie_tipo_agenda: this.$parent.agendamento.ie_tipo_agenda,
        dt_agenda: this.$root.moment(this.data).format("YYYY-MM-DD"),
        cd_cidade: this.$store.state.cdCidade,
        cd_estado: this.$store.state.cdEstado,
        //ie_atende_medclub_empresa: localStorage.getItem("MEDCLUB_TYPE_USER") === "true",
      };

      // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
      if (!params.ie_telemedicina) {
        params.cd_cidade = this.$store.state.cdCidade;
        params.cd_estado = this.$store.state.cdEstado;
      }

      if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;

      // update url slug
      const pesquisar_por = this.$route.params.pesquisar_por;

      const urlParams = new URLSearchParams(window.location.search);
      const param_estabelecimento = urlParams.get('estabelecimento')
        if(param_estabelecimento) {
          params.cd_estabelecimento = param_estabelecimento
        }

      if (pesquisar_por === "especialidade" || pesquisar_por === "medico") {
        const [slug_nm_especialista, slug_especialista, slug_especialidade] = this.$route.params.slug.split("_");
        

        const ds_telemedicina = this.$route.params.ds_telemedicina;

        let slug =
          slug_nm_especialista +
          "_" +
          slug_especialista +
          "_" +
          slug_especialidade +
          "_" +
          this.$root.moment(this.data).format("YYYY-MM-DD") 

        
        const query = param_estabelecimento ? { estabelecimento: param_estabelecimento } : {}
        if (!this.$parent.agendamento.is_odonto) {
          this.$router.replace({
            name: "agendarConsultaCompartilhar",
            params: { ds_telemedicina, pesquisar_por, slug },
            query
          });
        } else {
          this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug}, query });
        }
      }else{
        const query = param_estabelecimento ? { estabelecimento: param_estabelecimento } : {}
        this.$router.replace({
            query
        });
      }

      this.waiting.estabelecimentos = true;
      
      Agendamento.getEstabelecimentos(params).then((response) => {
        this.waiting.estabelecimentos = false;
        if (response.status === 200) {
          this.list = Object.values(response.data).length ? Object.values(response.data)[0] : [];
        }
      });

      delete params.dt_agenda
      Agendamento.getEstabelecimentosDisponiveis(params).then((response) => {
        if (response.status === 200) {
          this.estabelecimentosDisponiveis = response.data;
        }
      });
    },
    filtraPorEstabelecimento(id){
      if(this.estabelecimento_disponivel_selecionado == id){
        this.estabelecimento_disponivel_selecionado = null
        this.$router.push({query: {}})
        this.setup()
        return
      }
      this.estabelecimento_disponivel_selecionado = id
      this.$router.push({query: {estabelecimento: id}})
      this.setup()
    },
    clear_filters(){
      this.estabelecimento_disponivel_selecionado = null
      this.$router.replace({query: {}})
      this.setup()
    },
    selectEstabelecimento(estabelecimento, horario) {
      var usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
      var empresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
      if(empresa) {
          if(usuario.empresas_associado && usuario.empresas_associado.length && !usuario.empresas_associado[0]?.ie_status)
            this.$buefy.toast.open({
              duration: 8000,
              position: 'is-bottom',
              message: 'Não foi possível concluir a compra. Por favor, entre em contato com o departamento de Recursos Humanos da sua empresa.',
              type: 'is-danger',
        })
      return
      }
      this.$parent.agendamento.ie_hora_marcada = estabelecimento.ie_hora_marcada;
      this.$parent.agendamento._data = this.data;
      if (
        this.$parent.pesquisarPor !== "medico" ||
        !estabelecimento.ie_hora_marcada ||
        this.$parent.pesquisarPor !== "sintoma"
      ) {
        const ds_telemedicina = this.$route.params.ds_telemedicina;
        const pesquisar_por = this.$route.params.pesquisar_por;
        const cd_especialidade = this.$parent.agendamento.especialidade.id;
        const cd_estabelecimento = estabelecimento.id;
        const estabelecimento_name = String(estabelecimento.nm_estabelecimento)
          .replace(/\s/g, "-")
          .toLowerCase();

        const slug =
          "horarios" + // como é usado essa mesma rota só que com um slug diferente para listagem de especialidades, adiciona esse parametro
          estabelecimento_name +
          "_" +
          cd_especialidade +
          "_" +
          cd_estabelecimento +
          "_" +
          this.$root.moment(this.data).format("YYYY-MM-DD"); // slug should have cd_especialidade, cd_estabelecimento

        if(this.$route.name !== "prontoSocorro"){
          if (!this.$parent.agendamento.is_odonto) {
            this.$router.replace({
              name: "agendarConsultaCompartilhar",
              params: { ds_telemedicina, pesquisar_por, slug },
            });
          } else {
            this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
          }
        }

        this.$parent.agendamento.estabelecimento = estabelecimento;
        if (this.$parent.agendamento.especialista) {
          if (horario) this.$parent.agendamento.horario = horario;
          else this.$parent.agendamento.horario = estabelecimento.hr_horario[0];
          this.$parent.agendamentoCriado = true;
        }
      } else if (horario) {
        this.$parent.agendamento.estabelecimento = estabelecimento;
        this.$parent.agendamento.horario = horario;

        if (this.$route.name !== "prontoSocorro") this.$parent.agendamentoCriado = true;
      }
    },
  },
};
</script>
