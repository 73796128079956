<template lang="pug">
    .w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='back()')
                i.jam.jam-chevron-left
        .py-56(v-if='waiting.especialidades')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.especialidades')
        .w-full(v-else)
            .pt-4.pb-3.text-center.w-full

                .px-8.mb-2
                    span.text-gray-600.text-xs {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
                h1.font-bold.text-medclub-blue-300.text-sm Escolha a especialidade

            .p-1(v-if='list.length')
                b-input(placeholder='Pesquisar por especialidade' icon='magnify' v-model='filter.nm_especialidade')

            .flex.flex-wrap.pb-4
                .p-1.w-full.text-center(v-if='! listFiltered().length')
                    p.text-sm.my-6 Nenhuma especialidade encontrada.
                .p-1.w-full(v-else)
                    .w-full.shadow-md.border-b(v-for='especialidade, idx in listFiltered()' :key='idx')
                        .flex.items-center.bg-white.border.border-b-0.cursor-pointer(@click='selectEspecialidade(especialidade)')
                            .p-4.text-center(class='w-2/12 md:p-5 md:w-1/12')
                                img.w-full(:src='especialidade.aq_foto' class='md:w-8')
                            .px-2.py-4(class='w-8/12 md:w-10/12')
                                span.text-sm.text-gray-700.font-semibold {{ especialidade.nm_especialidade }}
                            .text-center(class='w-2/12 md:w-1/12')
                                span.text-2xl.text-gray-700.font-bold
                                    i.jam.jam-chevron-right
</template>

<script>
    import { Agendamento } from '../../../middleware'
    import { ordemBusca } from '../../../utils.js'
    export default {
        created () {
            this.waiting.especialidades = true
            let params = {
                ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
            }
            if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

            // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
            if(!params.ie_telemedicina) params.cd_cidade = this.$store.state.cdCidade

            params.ie_tipo_procedimento = this.$parent.agendamento.is_odonto ? 'OD' : 'CO'
            Agendamento.getProcedimentos(params).then(response => {
                this.waiting.especialidades = false
                if (response.status === 200) this.list = response.data
            })
        },
        data () {
            return {
                list: [],
                listFiltered () {
                    return ! this.filter.nm_especialidade ? this.list : ordemBusca(this.list, this.filter.nm_especialidade)
                },
                waiting: { especialidades: true },
                filter: { nm_especialidade: null }
            }
        },
        methods: {
            back () {
                
                this.$parent.agendamento.ie_hora_marcada = null
                if (this.$parent.pesquisarPor === 'especialidade' || this.$parent.pesquisarPor === 'data'){
                    this.$parent.pesquisarPor = null
                if(this.$parent.agendamento.is_odonto)
                    this.$router.push({name:"agendarOdonto"})
                else
                    this.$router.push({name:"agendarConsulta"})
                }
            },
            selectEspecialidade (especialidade) {
                let ds_telemedicina = this.$parent.agendamento.ie_telemedicina ? 'por-video' : 'presencial'
                let slug = especialidade.slug
                let pesquisar_por = this.$parent.pesquisarPor
                this.$parent.agendamento.especialidade = {
                    id: especialidade.id,
                    nm_especialidade: especialidade.nm_especialidade
                }
                
                if(this.$parent.agendamento.is_odonto)
                   this.$router.push({ name: 'agendarOdontoCompartilhar', params: { ds_telemedicina, pesquisar_por,slug } }) 
                else
                    this.$router.push({ name: 'agendarConsultaCompartilhar', params: { ds_telemedicina, pesquisar_por,slug } })
            }
        }
    }
</script>